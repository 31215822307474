<template>
    <div>
        <PageHeader :title="$t('streamings.streamer')"  />

    <div class="row">
        <aside class="col-xl-4">
          <div class="card text-center">
            <div class="card-body" v-if="streaming.creator.username">
              <div class="row">
                <div v-if="streaming.creator.avatar" class="mb-2 text-center col-xl-3">
                  <img class="rounded-circle avatar-sm" :src="streaming.creator.avatar" alt />
                </div>
                <div  class="mb-2 col-xl-8 text-left">
                  <h5 class="font-size-15 link"  @click="$router.push({name:'ProfilUser',params:{id:streaming.user_id}})">
                    {{streaming.creator.username}}
                  </h5>
                  <p class="text-muted">@{{streaming.name}}</p>
                </div>
              </div>



              <div class="group-details  pb-3 d-none d-md-block">
                <ul class="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.score')}}</p>
                    <h6>{{streaming.creator.score||0}}</h6>
                  </li>
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.level')}}</p>
                    <h6>{{streaming.creator.level}}</h6>
                  </li>
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.country')}}</p>
                    <h6>{{streaming.creator.country||'-'}}</h6>
                  </li>

                </ul>
              </div>
            </div>
            <div class="card-body" v-else>
              <div class="row">
                <div class="mb-2 text-center col-xl-3">
                  <b-skeleton type="avatar"  class="mr-2"></b-skeleton>
                </div>
                <div  class="mb-2 col-xl-8 text-left">
                  <b-skeleton class="mb-2"></b-skeleton>
                  <b-skeleton ></b-skeleton>
                </div>
              </div>



              <div class="group-details  pb-3 d-none d-md-block">
                <ul class="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.score')}}</p>
                    <b-skeleton></b-skeleton>
                  </li>
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.level')}}</p>
                    <b-skeleton></b-skeleton>
                  </li>
                  <li class="pl-3 pr-3">
                    <p class="mb-0">{{$t('profil_user.country')}}</p>
                    <b-skeleton></b-skeleton>
                  </li>

                </ul>
              </div>
            </div>


          </div>
            <div class="streamer-chat">
                <iframe frameborder="0" scrolling="yes" width="100%" height="400"
                        :src="'https://www.twitch.tv/embed/'+streaming.username_twitch+'/chat?parent='+this.$parent_stream"></iframe>

            </div>

        </aside>
        <div class="col-xl-8">
            <div class="tv-player-component">
                <iframe allowfullscreen="allowfullscreen" allow="autoplay; fullscreen" frameborder="0" height="575"
                        scrolling="no"  class="tv-player-iframe" width="100%"
                        :src="'https://player.twitch.tv/?channel='+streaming.username_twitch+'&parent='+this.$parent_stream"
                        data-visible="true">
                </iframe>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import PageHeader from "@/components/structure/page-header";
    import helper from "@/assets/helpers/helper";
    export default {
        name: "watchStream",
        components: {PageHeader},
        data(){
            return{
                streaming:{
                    creator:{}
                },
                link:'',
                user: {
                },
            }
        },
        created(){
            this.loadStreaming()

        },
        methods: {
            async loadStreaming() {
                let s = await helper.getAll('streamings/' + this.$route.params.id);
                this.streaming = s
            },

        }
    }
</script>

<style scoped>
/deep/.tw-root--theme-light .chat-room {

  background: #2a3042 !important;
  color: #fff !important;
  border: 0 solid #32394e !important;
}
/deep/.jFRoou{
  background-color: #2a3042!important;
  color:#fff!important;
  border: 0 solid #32394e!important;
}
</style>